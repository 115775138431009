<template>
  <div class="container-fluid p-4">
    <!-- back button -->
    <router-link
      :to="{
        name: 'reports'
      }"
      v-slot="{ href, route, navigate }"
      custom
    >
      <a :href="href" @click="navigate" class="mr-2"
        ><i class="fa fa-chevron-left mr-1"></i>Back</a
      >
    </router-link>

    <div class="d-flex flex-column pt-4"></div>
    <div class="d-flex justify-content-between">
      <!-- title -->
      <h1>Bulk Import Progress Report</h1>
      <div class="d-flex justify-content-between">
        <!-- total display -->
        <h1 class="mr-3">Total :{{ totalRows }}</h1>
      </div>
    </div>
    <!-- query form -->
    <div class="d-flex flex-column pt-4">
      <div class="d-flex flex-column mb-5">
        <b-card-group>
          <b-card class="bg-surface-2">
            <b-form>
              <!-- dropdown -->
              <b-form-group>
                <b-row>
                  <b-col class="d-flex flex-row justify-content-start">
                    <!-- status -->
                    <h2
                      class="mt-auto mb-auto mr-2"
                      style="font-family: LatoRegular"
                    >
                      Status:
                    </h2>
                    <multiselect
                      class="w-100"
                      id="status"
                      v-model="progressForm.status"
                      :options="statusOptions"
                      placeholder="Choose Status"
                      label="value"
                      track-by="text"
                    ></multiselect>
                    <!--Job type  -->
                  </b-col>
                  <b-col class="d-flex flex-row justify-content-start">
                    <h2
                      style="min-width: 58.42px; font-family: LatoRegular"
                      class="mt-auto mb-auto mr-2"
                    >
                      Job Type:
                    </h2>
                    <multiselect
                      class="w-100"
                      id="job_type"
                      v-model="progressForm.jobType"
                      :options="jobTypeOptions"
                      placeholder="Choose Job Type"
                      label="value"
                      track-by="text"
                    ></multiselect>
                  </b-col>
                </b-row>
              </b-form-group>
              <!-- search input -->
              <b-form-group>
                <b-row class="d-flex flex-row justify-content-between">
                  <b-col>
                    <b-form-input
                      class="w-100 text-truncate"
                      id="input-name"
                      v-model="$v.progressForm.searchInput.$model"
                      placeholder="Search by job number or username"
                      :state="validateFormSearch('searchInput')"
                      aria-describedby="progressForm.searchInput_feedback"
                    ></b-form-input>
                    <b-form-invalid-feedback
                      class="invalid-feedback"
                      id="progressForm.searchInput_feedback"
                    >
                      Your search must be at least 3 character or 3 digit
                      number.
                    </b-form-invalid-feedback>
                  </b-col>
                  <!-- date range -->
                  <b-col class="d-flex justify-content-start">
                    <h2
                      style="min-width: 160.16px; font-family: LatoRegular"
                      class="mt-auto mb-auto mr-2"
                    >
                      Job submission date time:
                    </h2>
                    <date-range-picker
                      ref="picker"
                      :opens="opens"
                      class="w-100"
                      v-model="progressForm.dateRange"
                      :locale-data="{
                        firstDay: 1,
                        format: 'yyyy-mm-dd HH:mm:ss'
                      }"
                      :time-picker-increment="1"
                      :timePicker="true"
                      :time-picker-seconds="true"
                    >
                      <template
                        v-if="progressForm.dateRange.startDate != null"
                        v-slot:input="picker"
                      >
                        {{ picker.startDate | dateTime }} -
                        {{ picker.endDate | dateTime }}
                      </template>
                      <template v-else v-slot:input>
                        <span
                          style="
                            color: var(--colour-text-placeholder);
                            font-size: 13px;
                          "
                          >Choose Date and Time</span
                        >
                      </template>
                    </date-range-picker>
                  </b-col>
                </b-row>
              </b-form-group>
              <!-- buttons -->
              <div class="d-flex justify-content-end">
                <!-- clear button -->
                <b-button
                  variant="dark"
                  @click="clearFilter"
                  size="sm"
                  class="d-flex jsutify-content-end mr-1"
                  >Clear</b-button
                >
                <!-- search button -->
                <b-button variant="light" @click="getSearchData" size="sm"
                  >Search</b-button
                >
              </div>
            </b-form>
          </b-card>
        </b-card-group>
      </div>
    </div>
    <!-- table content -->
    <div class="d-flex justify-content-between">
      <b-table
        :busy="busy"
        :items.sync="jobList"
        :fields="checkTableFields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :filter="checkinListFilter"
        :filter-included-fields="checkinListFilterOn"
        class="tableBorder"
        stacked="md"
        responsive
        striped
        show-empty
        small
        sticky-header
      >
        <template #cell(index)="row">
          <div>
            {{ getNumbering(row) }}
          </div>
        </template>

        <template #cell(job_number)="row">
          <div>
            <p
              v-if="isFailedJob(row.item.status)"
              style="font-size: var(--font-size-normal)"
            >
              {{ row.item.job_number }}
            </p>
            <a
              v-else
              @click="downloadFailedItem(row.item)"
              class="mr-2"
              v-b-tooltip.hover
              title="Download host error file"
            >
              <p style="font-size: var(--font-size-normal)">
                <u>
                  {{ row.item.job_number }}
                </u>
              </p>
            </a>
          </div>
        </template>

        <template #cell(job_submission_start_date_time)="row">
          <div>
            <p style="font-size: var(--font-size-normal)">
              {{ convertTime(row.item.created) }}
            </p>
          </div>
        </template>

        <template #cell(job_submission_end_date_time)="row">
          <div>
            <p style="font-size: var(--font-size-normal)">
              {{ convertTime(row.item.additional_metadata.completed_time) }}
            </p>
          </div>
        </template>

        <template #cell(user_name)="row">
          <div>
            <p style="font-size: var(--font-size-normal)">
              {{ row.item.username | humanizeText }}
            </p>
          </div>
        </template>

        <template #cell(user_status)="row">
          <div>
            <p style="font-size: var(--font-size-normal)">
              {{ row.item.status | humanizeText }}
            </p>
          </div>
        </template>

        <template #cell(user_action)="row">
          <b-button-group size="sm">
            <b-button
              variant="danger"
              v-b-tooltip.hover
              title="Delete Job"
              class="mr-1"
              v-if="hasStatus(row.item.status)"
              @click="handleDeleteJob(row.item)"
            >
              <i class="fa fa-trash"></i>
            </b-button>
          </b-button-group>
        </template>
      </b-table>
    </div>
    <!-- pagination -->
    <div class="d-flex justify-content-between w-100" v-if="!busy">
      <!-- perpage -->
      <div class="d-flex">
        <b-form-select
          id="per-page-select"
          v-model="perPage"
          :options="pageOptions"
          size="sm"
          @change="handlePerPageChange"
        ></b-form-select>
        Per Page
      </div>
      <!-- pagination -->
      <div class="d-flex">
        <b-pagination
          size="sm"
          class="d-flex"
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          @change="handlePageChange"
        ></b-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import DateRangePicker from "vue2-daterange-picker";
import { validationMixin } from "vuelidate";
import { minLength } from "vuelidate/lib/validators";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import jQuery from "jquery";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";

const FileDownload = require("js-file-download");

let $ = (jQuery = require("jquery"));
$ = $.extend(require("webpack-jquery-ui"));
$ = $.extend(require("webpack-jquery-ui/css"));
$ = $.extend(require("formBuilder"));
$ = $.extend(require("formBuilder/dist/form-render.min.js"));

export default {
  components: {
    "date-range-picker": DateRangePicker,
    Multiselect
  },
  mixins: [validationMixin],
  data() {
    let yesterday = moment().toDate();
    yesterday.setMinutes(0);
    yesterday.setSeconds(0);
    yesterday.setMilliseconds(0);
    let today = moment().add(1, "day").toDate();
    return {
      filterOn: true,
      opens: "left", //open which side
      perPage: 5, //displayed how many content per page
      currentPage: 1,
      pageOptions: [5, 10, 15, "All"],
      busy: false, //table load animation
      jobList: [], //list of job
      sortBy: "job_number", //sort by specific column
      sortDesc: false, //sort acending
      checkinListFilter: null, //clear current filter
      checkinListFilterOn: ["job_number"], //filter specific column
      totalRows: 0, //number of rows displyed
      statusOptions: [], // status options
      jobTypeOptions: [], //job options
      progressForm: {
        status: null,
        jobType: null,
        searchInput: null,
        dateRange: {
          startDate: null,
          endDate: null
        }
      },
      intervalId: null,
      // table headers
      checkTableFields: [
        {
          key: "index",
          label: "No.",
          sortDirection: "desc",
          thStyle: { width: "3%" }
        },
        {
          key: "job_number",
          label: "Job Number",
          sortable: true,
          thStyle: { width: "auto" }
        },
        {
          key: "job_type",
          label: "Job type",
          sortable: true,
          thStyle: { width: "auto" }
        },
        {
          key: "job_submission_start_date_time",
          label: "Job Submission Start Date Time",
          sortable: true,
          thStyle: { width: "auto" }
        },
        {
          key: "job_submission_end_date_time",
          label: "Job Submission End Date Time",
          sortable: true,
          thStyle: { width: "auto" }
        },
        {
          key: "user_name",
          label: "Username",
          sortable: true,
          thStyle: { width: "auto" }
        },
        {
          key: "user_status",
          label: "Status",
          sortable: true,
          thStyle: { width: "auto" }
        },
        {
          key: "user_action",
          label: "Actions",
          sortable: true,
          thStyle: { width: "auto" }
        }
      ]
    };
  },
  //validations requirement
  validations: {
    progressForm: {
      searchInput: { minLength: minLength(3) }
    }
  },
  computed: {
    ...mapGetters({
      getAPIServerURL: "session/getAPIServerURL"
    })
  },

  watch: {
    $route: function () {
      this.currentPage = this.$route.query.page
        ? parseInt(this.$route.query.page)
        : 1;
      this.perPage = this.$route.query.per_page
        ? parseInt(this.$route.query.per_page)
        : 5;
      if (this.filterOn) {
        this.getData();
      } else {
        this.getSearchData();
      }
    }
  },
  beforeDestroy() {
    try {
      clearInterval(this.intervalId);
    } catch (e) {
      console.log(e);
    }
  },
  created() {
    this.currentPage = this.$route.query.page
      ? parseInt(this.$route.query.page)
      : 1;
    this.perPage = this.$route.query.per_page
      ? parseInt(this.$route.query.per_page)
      : 5;
  },
  mounted: function () {
    this.getOptions();
    if (this.filterOn) {
      this.getData();
      //this.timer();
    } else {
      this.getSearchData();
    }
  },

  methods: {
    timer() {
      let that = this;
      let intervalId = window.setInterval(function () {
        console.log("Refresh Bulk Import data...", intervalId);
        that.getData();
      }, 60000);
      that.intervalId = intervalId;
    },
    //check status failed
    isFailedJob(status) {
      if (status == "FAILED") {
        return false;
      } else {
        return true;
      }
    },
    //download failed item
    downloadFailedItem(jobData) {
      let $this = this;
      let query = {};
      query = {
        job_id: jobData.id,
        job_number: jobData.job_number
      };
      query = Object.entries(query).reduce(
        (a, [k, v]) => (v == null ? a : ((a[k] = v), a)),
        {}
      );
      let API_URL =
        $this.getAPIServerURL + "/api/visitorservice/job/failed_content/?";
      let queryString = Object.keys(query)
        .map((key) => key + "=" + query[key])
        .join("&");

      API_URL += queryString;
      const client = $this.$root.getAjaxFetchClient(
        { responseType: "blob" },
        this
      );
      client
        .getRequest(API_URL)
        .then((data) => {
          FileDownload(data, "host_csv_error_[" + jobData.id + "].csv");
        })
        .catch((err) => {
          $this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: err.detail,
            message_type: "danger"
          });
        });
    },
    //submition validation
    onSubmitValidationProgressForm() {
      let $this = this;
      $this.$v.$touch();
      $this.$v.progressForm.$touch();

      if ($this.$v.progressForm.$anyError) {
        return false;
      } else {
        return true;
      }
    },
    //delete job function
    deleteJob(jobList) {
      let $this = this;
      let query = {
        job_number: jobList.job_number,
        previous_data: {}
      };
      let API_URL =
        $this.getAPIServerURL +
        "/api/visitorservice/job/" +
        jobList.id +
        "/?job_number=" +
        jobList.job_number;
      const client = $this.$root.getAjaxFetchClient();
      client
        .deleteRequest(API_URL, query)
        .then((data) => {
          console.log("data", data);
          let validUrl = null;
          outer_loop: for (let key in data?.result) {
            validUrl = key;
            break outer_loop;
          }
          if (data?.result[validUrl] == null) {
            $this.$store.dispatch("session/addGlobalAlertMessage", {
              message_text: "Deleted job successfully",
              message_type: "success"
            });

            this.getData();
          }
        })
        .catch((err) => {
          $this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: err.detail,
            message_type: "danger"
          });
        });
    },
    //handle delete job function
    handleDeleteJob(jobList) {
      let $this = this;
      /* const h = this.$createElement;
      const messageVNode = h("div", [
        h("h2", [
          `Are you sure you want to delete Job Number ${jobList.job_number}?`
        ])
      ]); */
      this.$bvModal
        .msgBoxConfirm(
          `Are you sure you want to delete Job Number ${jobList.job_number}?`,
          {
            //[messageVNode], {
            centered: true
          }
        )
        .then((value) => {
          if (value) {
            $this.deleteJob(jobList);
          }
        })
        .catch((err) => {
          // An error occurred
          $this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: err.detail,
            message_type: "danger"
          });
          console.log("Handle delete job error", err);
        });
    },
    //check status of delete button
    hasStatus(status) {
      let flag = null;
      if (status != "In Process") {
        flag = true;
      } else {
        flag = false;
      }
      return flag;
    },
    //covert time to specic format
    convertTime(x) {
      let done = moment(x).format("DD/MM/YYYY - hh:mm:ss A");
      return done;
    },
    //validate selected input
    validateFormSearch(field) {
      let result;
      if (field != null) {
        const { $dirty, $error } = this.$v.progressForm[field];
        result = $dirty ? !$error : null;
        return result;
      }
    },
    //clear buttonn function
    clearFilter: function () {
      let yesterday = moment().subtract(1, "hours").toDate();
      yesterday.setMinutes(0);
      yesterday.setSeconds(0);
      yesterday.setMilliseconds(0);
      let today = moment().toDate();
      this.progressForm = {
        status: null,
        jobType: null,
        searchInput: null,
        dateRange: {
          startDate: null,
          endDate: null
        }
      };
      this.filterOn = true;
      this.$v.$reset();
      if (this.$route.query.page || this.$route.query.per_page) {
        this.$router.replace({ query: null });
      } else {
        this.$router.push({
          ...this.$route,
          query: {
            ...this.$route.query,
            page: 1,
            per_page: 5
          }
        });
      }
      this.getData();
    },
    getNumbering(row) {
      if (this.perPage == "All") {
        return this.currentPage - 1 + row.index + 1;
      } else {
        return (this.currentPage - 1) * this.perPage + row.index + 1;
      }
    },
    getOptions() {
      let $this = this;
      let query = {
        page_index: 0,
        page_size: 0
      };
      query = Object.entries(query).reduce(
        (a, [k, v]) => (v == null ? a : ((a[k] = v), a)),
        {}
      );

      let API_URL = $this.getAPIServerURL + "/api/visitorservice/job/?";
      let queryString = Object.keys(query)
        .map((key) => key + "=" + query[key])
        .join("&");

      API_URL += queryString;
      const client = $this.$root.getAjaxFetchClient();
      client
        .getRequest(API_URL)
        .then((data) => {
          let result = data.result;
          let validUrl = "";
          if (result != undefined) {
            outer_loop: for (let key in result) {
              if (result[key] != undefined) {
                validUrl = key;
                break outer_loop;
              }
            }
          }

          if (
            result[validUrl]["result"] != undefined &&
            Array.isArray(result[validUrl]["result"])
          ) {
            if (result[validUrl]["result"].length > 0) {
              let options_status = [];
              let options_job_type = [];

              let arrayStatus = [];
              let arrayJobType = [];

              let object = {
                text: null,
                value: null
              };
              let isNotExistYet = false;
              let status = null;
              let jobs = null;
              $.each(result[validUrl]["result"], function (i, el) {
                if ($.inArray(el.status, arrayStatus) === -1) {
                  arrayStatus.push(el.status);
                  isNotExistYet = true;

                  if (isNotExistYet) {
                    isNotExistYet = false;
                    status = el.status;
                    object = {
                      text: status,
                      value: status
                    };
                    options_status.push(object);
                  }
                }

                if ($.inArray(el.job_type, arrayJobType) === -1) {
                  arrayJobType.push(el.job_type);
                  isNotExistYet = true;

                  if (isNotExistYet) {
                    isNotExistYet = false;
                    jobs = el.job_type;
                    object = {
                      text: jobs,
                      value: jobs
                    };
                    options_job_type.push(object);
                  }
                }
              });

              $this.statusOptions = options_status;
              $this.jobTypeOptions = options_job_type;
            }
          }
        })
        .catch((err) => {
          console.log("Get all Jobs Error:", err);
        });
    },
    //get job function
    getData() {
      this.busy = true;
      this.jobList = [];
      let $this = this;
      let query = {
        page_index: $this.currentPage,
        page_size: $this.perPage
      };
      query = Object.entries(query).reduce(
        (a, [k, v]) => (v == null ? a : ((a[k] = v), a)),
        {}
      );

      let API_URL = $this.getAPIServerURL + "/api/visitorservice/job/?";
      let queryString = Object.keys(query)
        .map((key) => key + "=" + query[key])
        .join("&");

      API_URL += queryString;

      const client = $this.$root.getAjaxFetchClient();
      if ($this.filterOn) {
        client
          .getRequest(API_URL)
          .then((data) => {
            let result = data.result;
            let validUrl = "";
            if (result != undefined) {
              outer_loop: for (let key in result) {
                if (result[key] != undefined) {
                  validUrl = key;
                  break outer_loop;
                }
              }
            }

            if (
              result[validUrl] != undefined &&
              Array.isArray(result[validUrl]["result"])
            ) {
              // separate
              for (let key in result[validUrl]["result"]) {
                let jobs_data = result[validUrl]["result"][key];
                $this.jobList.push(jobs_data);
              }
            }

            if (result[validUrl]["total_jobs"] !== undefined) {
              $this.totalRows = parseInt(result[validUrl]["total_jobs"]);
            }
            if (result[validUrl]["page_index"] !== undefined) {
              $this.currentPage = parseInt(result[validUrl]["page_index"]);
            }

            if (result[validUrl]["page_size"] !== undefined) {
              $this.perPage = parseInt(result[validUrl]["page_size"]);
            }
            $this.busy = false;
          })
          .catch((err) => {
            this.busy = false;
            console.log("Get all Jobs Error:", err);
          });
      }
    },
    //search function
    getSearchData() {
      this.jobList = [];
      this.busy = true;
      this.filterOn = false;

      let $this = this;
      let validationForm = $this.onSubmitValidationProgressForm();
      let query = {};
      if (validationForm) {
        query = {
          /* page_index: $this.currentPage,
          page_size: $this.perPage, */
          start_date: $this.progressForm.dateRange.startDate
            ? moment($this.progressForm.dateRange.startDate).format(
                "YYYY-MM-DD HH:mm:ss"
              )
            : null,
          end_date: $this.progressForm.dateRange.endDate
            ? moment($this.progressForm.dateRange.endDate).format(
                "YYYY-MM-DD HH:mm:ss"
              )
            : null,
          status: $this.progressForm.status
            ? $this.progressForm.status.value
            : null,
          job_type: $this.progressForm.jobType
            ? $this.progressForm.jobType.value
            : null,
          keyword: $this.progressForm.searchInput
            ? $this.progressForm.searchInput
            : null
        };
        query = Object.entries(query).reduce(
          (a, [k, v]) => (v == null ? a : ((a[k] = v), a)),
          {}
        );

        let API_URL =
          $this.getAPIServerURL + "/api/visitorservice/job/search/?";

        let queryString = Object.keys(query)
          .map(
            (key) =>
              encodeURIComponent(key) + "=" + encodeURIComponent(query[key])
          )
          .join("&");
        API_URL += queryString;
        const client = $this.$root.getAjaxFetchClient();
        client
          .getRequest(API_URL)
          .then((data) => {
            let result = data.result;
            let validUrl = "";
            if (result != undefined) {
              outer_loop: for (let key in result) {
                if (result[key] != undefined) {
                  validUrl = key;
                  break outer_loop;
                }
              }
            }
            if (result[validUrl]["result"] != undefined) {
              console.log(result[validUrl]);
              for (let key in result[validUrl]) {
                for (let key1 in result[validUrl][key]) {
                  $this.jobList.push(result[validUrl][key][key1]);
                }
              }
            }

            $this.currentPage = result[validUrl]["page_index"]
              ? parseInt(result[validUrl]["page_index"])
              : 1;
            $this.perPage = result[validUrl]["page_size"]
              ? parseInt(result[validUrl]["page_size"])
              : "All";

            $this.totalRows = $this.jobList.length;

            this.busy = false;
          })
          .catch((err) => {
            this.busy = false;
            console.log("Get Jobs Data Error:", err);
          });
      }
    },
    //table page
    handlePageChange(page) {
      this.$router.push({
        ...this.$route,
        query: {
          ...this.$route.query,
          page: parseInt(page)
        }
      });
      console.log("upddated page", page);
    },
    //table content display
    handlePerPageChange(pageSize) {
      this.$router.push({
        ...this.$route,
        query: {
          ...this.$route.query,
          page: 1,
          per_page: parseInt(pageSize)
        }
      });
    }
  }
};
</script>

<style lang="scss">
.custom-select {
  background: #fff
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-caret-down-fill' viewBox='0 0 16 16'%3e%3cpath fill='black' d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/%3e%3c/svg%3e")
    right 0.75rem center/8px 10px no-repeat !important;
}
#per-page-select {
  background: #fff
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23161412' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
    right 0.75rem center/8px 10px no-repeat !important;
}
.multiselect__tags {
  border: 1px solid black;
}

.calendars {
  background: var(--colour-nav-bg);
}

.drp-buttons {
  background: var(--colour-nav-bg);
}

.calendar-table {
  color: var(--colour-overlay-gray-dark);
}
</style>
